<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newExam"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Exam</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @onShowDetails="onShowDetails"
              @onDelete="onDelete"
              @changed="getExams"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <exams-form
      v-if="showForm"
      :item="selExam"
      @close="closeForm"
      @onSave="initTable"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import ExamsForm from './ExamsForm.vue';

export default {
  components: {
    VueTable,
    ExamsForm,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'questions',
            sortable: true,
            callback: (x) => x.length,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Exam',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      showForm: false,
      selExam: null,
    };
  },
  mounted() {
    this.initTable();
  },
  methods: {
    getExams(params) {
      this.request(this.$API.PLACEMENT_EXAMS.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onShowDetails(item) {
      this.selExam = { ...item };
      this.selExam.questions.map((x) => {
        const aux = x;
        aux.key = Math.random() * Date.now();
        return aux;
      });
      this.selExam.parameters.map((x) => {
        const aux = x;
        aux.key = Math.random() * Date.now();
        return aux;
      });
      this.showForm = true;
    },
    onDelete(item) {
      this.request(this.$API.PLACEMENT_EXAMS + item.placement_exam_id, 'delete', null, () => {
        this.initTable();
        this.fireSuccess('Exam deleted');
      });
    },
    initTable() {
      this.$refs.vtable.init();
    },
    newExam() {
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
      this.selExam = null;
    },
  },
};
</script>

<style scoped></style>
