<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="close"
  >
    <template slot="title">
      Exam Form
    </template>
    <template
      v-if="!loading"
      slot="body"
    >
      <ValidationObserver ref="form">
        <div class="md-layout">
          <div class="md-layout-item">
            <form-text
              v-model="form.title"
              name="title"
              label="Title*"
              rules="required|max:120"
            />

            <small class="mt-3">Starting*</small>
            <trumbowyg
              v-model="form.body_html"
              :config="config"
              class="form-control"
              name="body_html"
            />

            <small class="mt-3">Ending*</small>
            <trumbowyg
              v-model="form.confirm_html"
              :config="config"
              class="form-control"
              name="confirm_html"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <h4>Score Table</h4>
            <md-table class="table">
              <md-table-row>
                <md-table-head style="width: 30%">
                  Level
                </md-table-head>
                <md-table-head>Question weight</md-table-head>
                <md-table-head>Min Score (%)</md-table-head>
                <md-table-head>Max Score (%)</md-table-head>
                <md-table-head style="width:1%" />
              </md-table-row>
              <md-table-row
                v-for="(param, index) in form.parameters"
                :key="param.key"
              >
                <md-table-cell>{{ param.level.name }}</md-table-cell>
                <md-table-cell>
                  <form-number
                    v-model="form.parameters[index].weight"
                    rules="required|min_value:0"
                  />
                </md-table-cell>
                <md-table-cell>
                  <form-number
                    v-model="form.parameters[index].min_score"
                    :rules="`required|min_value:${
                      index === 0 ? '0' : searchMinScore(index)
                    }`"
                  />
                </md-table-cell>
                <md-table-cell>
                  <form-number
                    v-model="form.parameters[index].max_score"
                    :rules="`required|min_value:${
                      index === 0 ? '0' : searchMaxScore(index)
                    }`"
                  />
                </md-table-cell>
                <md-table-cell>
                  <md-button
                    class="md-danger md-simple md-icon-button"
                    :disabled="form.parameters.length === 1"
                    @click="removeParameter(index)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <h4>Questions List*</h4>

            <div>
              <ExamQuestionsSelect
                v-model="selQuestionId"
                :rules="examQuestionRules"
                @change="selQuestion = $event"
              />
              <md-button
                class="md-info md-sm md-block"
                :disabled="!selQuestionId"
                @click="addQuestion"
              >
                Select Question
              </md-button>
            </div>

            <div class="md-content md-table-content md-scrollbar md-theme-default">
              <draggable
                v-model="form.questions"
                group="questions"
                handle=".handler"
                tag="table"
                @end="onChangeSort"
              >
                <template slot="header">
                  <tr
                    class="md-table-row"
                  >
                    <th class="md-table-head">
                      <div class="md-table-head-container">
                        <div class="md-table-head-label" />
                      </div>
                    </th>
                    <th class="md-table-head">
                      <div class="md-table-head-container">
                        <div class="md-table-head-label">
                          Level
                        </div>
                      </div>
                    </th>
                    <th class="md-table-head">
                      <div class="md-table-head-container">
                        <div class="md-table-head-label">
                          Category
                        </div>
                      </div>
                    </th>
                    <th class="md-table-head">
                      <div class="md-table-head-container">
                        <div class="md-table-head-label">
                          Question
                        </div>
                      </div>
                    </th>
                    <th class="md-table-head">
                      <div class="md-table-head-container">
                        <div class="md-table-head-label">
                          Answer
                        </div>
                      </div>
                    </th>
                    <th
                      class="md-table-head"
                      style="width:1%"
                    />
                  </tr>
                </template>

                <tr
                  v-for="(q, index) in form.questions"
                  :key="q.key"
                  class="md-table-row"
                >
                  <td class="md-table-cell">
                    <div class="md-table-cell-container handler">
                      <i
                        class="fa fa-grip-vertical"
                        style="cursor: grab;"
                      />
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ q.level.name }}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ q.category }}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ trimHtml(q.question) }}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ q.correctAnswer ? q.correctAnswer.text : null }}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <md-button
                        class="md-danger md-simple md-icon-button"
                        @click="removeQuestion(index)"
                      >
                        <md-icon>close</md-icon>
                      </md-button>
                    </div>
                  </td>
                </tr>
              </draggable>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        class="md-primary pull-right"
        @click="saveForm"
      >
        <md-icon class="mr-3">
          save
        </md-icon>

        Save
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import draggable from 'vuedraggable';
import { extend } from 'vee-validate';
import { required, max, min_value } from 'vee-validate/dist/rules';
import {
  FormText,
  FormNumber,
} from '@/components/Inputs';
import {
  ExamQuestionsSelect,
} from '@/components/Inputs/selects';
import stringMix from '@/mixins/string';

extend('required', required);
extend('max', max);
extend('min_value', min_value);

export default {
  components: {
    draggable,
    FormText,
    FormNumber,
    ExamQuestionsSelect,
  },
  mixins: [stringMix],
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        title: null,
        body_html: null,
        confirm_html: null,
        parameters: [],
        questions: [],
      },
      selQuestionId: null,
      selQuestion: null,
      config: {
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['strong', 'em', 'del'],
          ['superscript', 'subscript'],
          ['link'],
          ['insertImage'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
        ],
      },
    };
  },
  computed: {
    examQuestionRules() {
      return this.form.questions.length === 0 ? 'required' : '';
    },
  },
  mounted() {
    if (this.item) {
      this.form = this.item;
      this.sortQuestions();
    } else {
      this.getExamDefault();
    }
  },
  methods: {
    sortQuestions() {
      this.form.questions = this.form.questions.sort((a, b) => {
        if (a.placement_exam_question.sort > b.placement_exam_question.sort) return 1;
        return -1;
      });
      this.form.questions.forEach((x, ix) => {
        x.sort = ix + 1;
      });
    },
    searchMinScore(index) {
      return index > 0 && this.form.parameters[index].min_score === 0 ? index : Math.max(
        ...this.form.parameters.map(
          (parameters, _index) => (_index < index) && parameters.max_score,
        ),
      );
    },
    searchMaxScore(index) {
      return index > 0 && this.form.parameters[index].max_score === 0
        ? index : this.form.parameters[index].min_score;
    },
    close() {
      this.$emit('close');
    },
    addQuestion() {
      this.form.questions.push({ ...this.selQuestion, sort: this.form.questions.length + 1 });
      this.selQuestion = null;
      this.selQuestionId = null;
    },
    removeQuestion(index) {
      this.form.questions.splice(index, 1);

      this.form.questions.forEach((x, ix) => {
        x.sort = ix + 1;
      });
    },
    removeParameter(index) {
      this.form.parameters.splice(index, 1);
    },
    getExamDefault() {
      this.loading = true;
      this.request('placement_test/default', 'get', null, ({ data }) => {
        this.form = data;
        this.sortQuestions();
        this.loading = false;
      });
    },
    onChangeSort() {
      this.form.questions.forEach((x, ix) => {
        x.sort = ix + 1;
      });
    },
    saveForm() {
      const validBodyHtml = !!this.form.body_html;
      const validConfirmHtml = !!this.form.confirm_html;
      const validQuestions = !!this.form.questions.length;
      const canSubmit = validBodyHtml && validConfirmHtml && validQuestions;

      this.$refs.form.validate().then((isValid) => {
        if (isValid && canSubmit) {
          this.fireConfirm(
            `${this.item ? 'Editing' : 'Creating'} Exam`,
            'Did you check all the data and you confirm you want to create the exam ?',
          ).then(() => {
            if (this.item) {
              this.request(this.$API.PLACEMENT_EXAMS + this.item.placement_exam_id, 'put', this.form, (res) => {
                this.fireSuccess(res);
                this.$emit('onSave');
                this.$refs.modal.close();
              });
            } else {
              this.request(this.$API.PLACEMENT_EXAMS, 'post', this.form, (res) => {
                this.fireSuccess(res);
                this.$emit('onSave');
                this.$refs.modal.close();
              });
            }
          });
        } else {
          this.fireWarning('Please check the form, some fields may be empty or wrong.');
        }
      });
    },
  },
};
</script>
<style scoped>
::v-deep .trumbowyg-box.trumbowyg {
  margin-top: 0 !important;
}

::v-deep .md-table-cell {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

::v-deep .md-table-cell .md-field {
  margin: 0;
}
</style>
